@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

$progress-bar-height: 115px;
$action-buttons-height: 160px;

.progress-layout {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  .progress-layout__bar {
    display: flex;
    height: $progress-bar-height;
  }

  .progress-layout__step-content {
    display: flex;
    max-height: calc(100% - #{$progress-bar-height});
    height: 100%;
    position: relative;
  }

  .progress-layout__step-content__viewer {
    flex: 3;
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    .viewer {
      flex: 1;
    }

    .progress-layout__step-content__actions {
      margin-bottom: 50px;
    }
  }
}
