@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.main-header__logo {
  @include generalFont(18px, 18px, white);
  font-weight: 300;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 30px;
}
