@import '../../assets//styles/mixins.scss';
@import '../../assets//styles/variables.scss';

.design-supports {
  .design-supports__content__inputs > * {
    margin-bottom: 35px;
  }

  .design-supports__support-preview {
    position: absolute;
    right: 35px;
    top: 85px;
    z-index: 1;
  }
}
