@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.progress-bar {
  flex: 1;
  padding: $main-block-padding 0;
  display: flex;
  justify-content: space-around;
  overflow: hidden;

  .progress-bar__item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;
    pointer-events: none;

    &.completed,
    &.active {
      pointer-events: initial;
      cursor: pointer;
    }
  }

  .progress-bar__item__name {
    @include generalFont(15px, 20px, $progress-bar-uncomplete-color);
  }
  .progress-bar__item__mark {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    position: relative;
    box-sizing: border-box;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      border: 1px solid $progress-bar-uncomplete-color;
      bottom: calc(50% - 1px);
      left: -50%;
    }
  }

  .progress-bar__item__mark__circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 10px solid $progress-bar-uncomplete-color;
    z-index: 1;
  }

  .progress-bar__item:first-of-type {
    .progress-bar__item__mark:before {
      content: '';
      position: absolute;
      width: 50%;
      border: 1px solid $progress-bar-color;
      box-sizing: border-box;
      bottom: calc(50% - 1px);
      left: 0;
    }
  }
  .progress-bar__item:last-of-type {
    .progress-bar__item__mark:after {
      content: '';
      position: absolute;
      width: 50%;
      border: 1px solid $progress-bar-uncomplete-color;
      box-sizing: border-box;
      bottom: calc(50% - 1px);
      right: 0;
    }
  }

  .progress-bar__item.completed:not(.active) {
    .progress-bar__item__name {
      color: $progress-bar-color;
    }
    .progress-bar__item__mark:before {
      border-color: $progress-bar-color;
    }
    .progress-bar__item__mark__circle {
      position: relative;
      border-color: $progress-bar-color;
    }
    .progress-bar__item__mark__circle:before {
      content: '';
      position: absolute;
      width: 4px;
      height: 8px;
      transform: rotate(45deg);
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      bottom: -3px;
      left: -3px;
    }
  }

  .progress-bar__item.active {
    .progress-bar__item__name {
      color: $progress-bar-color;
    }
    .progress-bar__item__mark:before {
      border-color: $progress-bar-color;
    }
    .progress-bar__item__mark__circle {
      width: 30px;
      height: 30px;
      border-color: $progress-bar-color;
      background: white;
    }
  }
}
