@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.main-navigation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .main-navigation__brand {
    flex: 0 0 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
  }

  .main-navigation__brand__logo {
    background: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include generalFont(27px, 25px, $main-toolbar-color);
  }

  .main-navigation__routes {
    padding-top: 20px;
    flex: 1;
  }
  .main-navigation__routes__item {
    flex: 1;
    height: 65px;

    &:hover {
      background: $main-hover-toolbar-color;
    }

    svg {
      stroke: white;
      padding: 18px;
    }
  }

  .main-navigation__user {
    flex: 0 0 80px;
  }

  .main-navigation__user__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid white;
    margin: 10px auto 0;
    cursor: not-allowed;
  }
}
