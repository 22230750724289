@import './variables.scss';

@mixin general-border {
  border: 1px solid $initial-color;
  border-radius: 6px;
  box-shadow: 2px -2px 5px $initial-color;
}

@mixin generalFont($font-size, $line-height, $color: $general-text-color) {
  font-family: 'Segoe UI';
  font-size: $font-size;
  line-height: $line-height;
  font-weight: 500;
  font-style: normal;
  color: $color;
}

@mixin custom-scroll {
  &::-webkit-scrollbar {
    width: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-background-color;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 6px;
    visibility: hidden;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:focus::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
