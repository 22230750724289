@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/mixins.scss';

.general-button {
  @include generalFont(14px, 14px, white);
  text-transform: none;
  background: $primary-color;

  &:hover,
  &:focus {
    color: white;
    background: $dark-primary-color;
  }
}
