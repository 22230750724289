@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.loadable-container {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: $general-border-radius;
  overflow: hidden;
  position: relative;
  background: linear-gradient(45deg, #ffffff13 -0.64%, #171c4e31 94%);
}

.loadable-container::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 0.01) infinite;
  background: linear-gradient(90deg, transparent 20%, #ffffff4d 50%, transparent 100%);
}

@keyframes load {
  from {
    left: -150px;
  }

  to {
    left: 100%;
  }
}

.loadable-container.loaded {
  background: rgba(255, 0, 0, 0);
}

.loadable-container.loaded::before {
  animation: none;
  background: none;
}

.loadable-container__image {
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loadable-container__image.loaded {
  opacity: 1;
}
