@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables.scss';

.parts-page {
  flex: 1;
  display: flex;
  flex-direction: column;

  .parts-page__list {
    @include custom-scroll;
    max-height: 100%;
    overflow: auto;
  }

  .parts-page__list__item {
    cursor: pointer;
    box-sizing: border-box;
    position: relative;

    .mark {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 5px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:hover {
      background: linear-gradient(270deg, white 0%, white 5%, #39a1c017 100%);
    }
    &.selected {
      background: linear-gradient(270deg, white 0%, white 5%, #39a1c017 100%);

      .mark {
        background: $primary-color;
      }
    }
  }

  .parts-page__actions {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;

    button {
      min-width: 155px;
    }

    .button-spinner {
      border-color: white white white transparent;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .parts-page__data-table {
    flex: 1;
    width: 100%;
  }
}
