@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables.scss';

.support-preview {
  border: 1px solid $main-toolbar-color;
  background: $general-preloader-background;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .support-preview__name {
    @include generalFont(16px, 16px);
    margin-bottom: 15px;
  }

  .support-preview__image {
    width: 110px;
    height: 110px;
  }
}
