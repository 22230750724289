@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.step-navigation {
  @include generalFont(18px, 18px);
  height: 100%;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
