@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables.scss';

.faces-radio-input {
  cursor: pointer;
  background: $gray-background-color;
  cursor: pointer;
  position: relative;
  border: 1px solid;
  box-sizing: border-box;

  .faces-radio-input__label {
    @include generalFont(16px, 16px);
  }
  .faces-radio-input__mark {
    width: 18px;
    height: 18px;
    margin: 0 15px;
    border-radius: 50%;
  }

  &:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    left: -10px;
    top: 0;
    background: inherit;
  }

  &.selected {
    background: $dark-gray-background-color;
    .faces-radio-input__label {
      color: white;
    }
    .faces-radio-input__mark {
      background: white !important;
    }
  }
}
