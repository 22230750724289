@import '../../../../assets/styles/mixins.scss';
@import '../../../../assets/styles/variables.scss';

.file-input {
  .file-input__button {
    @include generalFont(12px, 15px, white);
    padding: 0 23px;
    background: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .file-input__button__input {
    position: absolute;
    visibility: hidden;
  }
}
