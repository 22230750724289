@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.main-layout {
  height: 100%;
  display: flex;
  flex: 1;

  .main-layout__nav-bar {
    flex: 0 0 65px;
    background: $main-toolbar-color;
  }

  .main-layout__page {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .main-layout__page__header {
    background: $main-toolbar-color;
    flex: 0 0 $header-height;
  }

  .main-layout__page__content {
    display: flex;
    width: 100%;
    flex: 1;
    max-height: calc(100% - #{$header-height});
  }
}
