@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.part-list-item {
  display: flex;
  margin: 0 10px 0 70px;
  padding: 20px 0;
  border-bottom: 1px solid $item-list-border-color;
  // max-width: 300px;

  .loadable-container {
    border-radius: $general-border-radius;
  }

  .part-list-item__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0 20px;
    width: 100%;
  }
  .part-list-item__data__name {
    @include generalFont(16px, 16px);
    font-weight: 400;
    width: 100%;
  }
  .part-list-item__data__next-step {
    align-self: baseline;
    margin-top: 7px;
    @include generalFont(14px, 14px, $primary-color);
    position: relative;
    padding-left: 15px;
    justify-self: baseline;
    flex: 1;

    &:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      left: 0;
      top: 4px;
      background: $primary-color;
    }
  }
  .part-list-item__data__actions {
    align-self: baseline;

    // move to mixin simple button
    button {
      border: none;
      @include generalFont(12px, 12px, $disabled-text-color);
      height: initial;
      text-transform: none;
      padding: 5px 10px;
      margin: 0;
      display: flex;
      align-items: center;

      .delete-button__icon-wrapper {
        width: 13px;
        height: 13px;
        margin-right: 5px;

        .button-spinner {
          border-color: $disabled-text-color $disabled-text-color $disabled-text-color transparent;
        }
      }

      svg {
        fill: $disabled-text-color;
        width: 13px;
        height: 13px;
      }

      &:hover {
        background: $warning-color-opacity;
      }
    }
  }

  &.selected {
    .part-list-item__data__name {
      font-weight: 600;
    }
  }
}
