@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.inputs-block {
  border: 1px solid $progress-bar-uncomplete-color;
  padding: 30px 15px 15px 15px;
  position: relative;

  .inputs-block__name {
    @include generalFont(16px, 18px, $disabled-text-color);
    text-transform: uppercase;
    padding: 0 10px;
    position: absolute;
    top: -10px;
    left: 10px;
    background: white;
  }

  .row {
    display: flex;
    margin-bottom: 20px;
  }

  .row > div {
    margin: 0;
  }

  .row > div:not(:last-of-type) {
    margin-right: 7px;
  }
  .row > div:not(:first-of-type) {
    margin-left: 7px;
  }
}
