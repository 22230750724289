@import '../../../../assets/styles/mixins.scss';
@import '../../../../assets/styles/variables.scss';

$general-checkbox-size: 17px;

.general-checkbox-input {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .general-checkbox-input__label {
    @include generalFont(14px, 14px);
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: $general-checkbox-size + 10px;
    height: $general-checkbox-size;
  }
  .general-checkbox-input__input {
    margin: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .general-checkbox-input__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid $progress-bar-uncomplete-color;
    border-radius: 4px;
    height: $general-checkbox-size;
    width: $general-checkbox-size;
    background-color: white;

    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  .general-checkbox-input__input:checked ~ .general-checkbox-input__checkmark {
    background-color: $main-toolbar-color;

    &:after {
      display: block;
      left: 6px;
      top: 3px;
      width: 3px;
      height: 6px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
