@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.viewer-preloader {
  position: absolute;
  display: flex;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(136, 136, 136, 0.1);
}

// .viewer-preloader__spinner {
//   border-width: 6px;
//   border-style: solid;
//   border-color: $general-spinner-color $general-spinner-color $general-spinner-color transparent;
//   box-sizing: border-box;
//   width: 80px;
//   height: 80px;
//   border-radius: 50%;
//   animation: spin 2s linear infinite;
//   position: relative;
//   margin-right: 10px;
//   @keyframes spin {
//     100% {
//       transform: rotate(360deg);
//     }
//   }
// }
