@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.overall-progress-marker {
  flex: 0 0 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .overall-progress-marker__circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $progress-bar-uncomplete-color;
    margin-top: 5px;
    box-sizing: border-box;
  }

  .overall-progress-marker__step-line {
    background: $progress-bar-uncomplete-color;
    width: 2px;
    min-height: 10px;
    flex: 1;
    margin: 8px 0 7px;
  }
  .vertical-dots {
    height: 15px;
  }
  .vertical-dots__item {
    background: $disabled-text-color;
  }

  &.contain-text {
    .overall-progress-marker__circle {
      display: flex;
      justify-content: center;
      align-items: center;
      @include generalFont(14px, 16px, white);
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }

  &:not(.completed) {
    .overall-progress-marker__step-line {
      background: none;
    }
  }

  &.completed {
    .overall-progress-marker__circle {
      background: $progress-bar-color;
    }
    .vertical-dots__item,
    .overall-progress-marker__step-line {
      background: $progress-bar-color;
    }
  }

  &.active {
    .overall-progress-marker__circle {
      background: none;
      color: $progress-bar-color;
      border: 2px solid $progress-bar-color;
    }
  }

  &.next-step {
    .overall-progress-marker__circle {
      background: $light-primary-color;
      color: $progress-bar-color;
      border: none;
    }
  }
}
