@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.general-table {
  @include generalFont(14px, 16px, $general-table-text-color);
  width: 100%;
  margin-bottom: 35px;
  border-collapse: collapse;
  border: 1px solid $general-table-border-color;

  * {
    padding: 0;
  }

  tr > th,
  tr > td {
    padding: 0 20px;
    font-weight: 400;
  }

  thead {
    height: 40px;
    color: $general-table-title-color;
    background: $general-table-header-background-color;
    text-transform: uppercase;
  }

  thead th {
    font-size: 12px;
  }

  tbody {
    tr,
    td {
      height: 35px;
      box-sizing: border-box;
    }
  }

  .aling-center {
    text-align: center;
  }
  .aling-start {
    text-align: left;
  }
  .aling-end {
    text-align: right;
  }
}
