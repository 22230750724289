@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.global-preloader {
  position: fixed;
  inset: 0px;
  display: flex;
  z-index: 999;
  pointer-events: auto;
  background: $general-preloader-background;
  justify-content: center;
  align-items: center;
}

.transparent-preloader__spinner {
  border-width: 6px;
  border-style: solid;
  border-color: $general-spinner-color $general-spinner-color $general-spinner-color transparent;
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  position: relative;
  margin-right: 10px;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
