@import './variables.scss';
@import './mixins.scss';

$page-title-height: 55px;

.app-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  overflow: auto;
}

.general-page-style {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  flex: 1;
  min-width: 450px;

  .general-page-style__content {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-height: 100%;
  }

  .general-page-style__content__page-name {
    @include generalFont(36px, 36px);
    height: $page-title-height;
    padding: 20px 0 0;
    font-weight: 900;
    padding-left: 70px;
    flex: 0 0 100%;
    box-sizing: border-box;
  }

  .general-page-style__content__left-block {
    flex: 1 0;
    padding: $main-block-padding $main-block-padding $main-block-padding 0;
    height: calc(100% - #{$page-title-height} - #{$main-block-padding});
    max-height: calc(100% - #{$page-title-height});
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    @include custom-scroll;
    overflow: auto;
  }

  .general-page-style__content__left-block:not(.parts-page__content) {
    padding-left: 70px;
  }

  .general-page-style__content__right-block {
    flex: 2;
    padding: $main-block-padding;
    display: flex;
    flex-direction: column;
  }
}

.general-input-style {
  input,
  select,
  input[type='number'] {
    &:active,
    &:hover,
    &:focus {
      border-color: $main-toolbar-color;
    }
  }
  input,
  input[type='number'] {
    height: 35px;
  }
}
