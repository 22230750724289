@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables.scss';

.optimizer-options__content {
  .optimizer-options__content__title {
    @include generalFont(20px, 23px, $blue-title-color);
    margin-bottom: 20px;
  }
  .optimizer-options__content__inputs {
    @include custom-scroll;
    overflow: auto;
  }
  .optimizer-options__content__actions {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;

    .general-button {
      margin: 0;
    }
  }
  .optimizer-options__content__inputs .inputs-block {
    margin-top: 40px;

    &:first-of-type {
      margin-top: 20px;
    }

    .general-checkbox-input.disabled .general-checkbox-input__label {
      color: $disabled-text-color;
    }
  }
}
