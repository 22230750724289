@import '../../../assets/styles/variables.scss';

.vertical-dots {
  position: relative;
  width: 100%;
  height: 100%;

  .vertical-dots__item {
    position: absolute;
    width: 2px;
    height: 2px;
    background: black;
    border-radius: 50%;
    box-sizing: border-box;
    left: 0;

    &.first {
      top: 25%;
    }
    &.second {
      top: 50%;
    }
    &.third {
      top: 75%;
    }
  }
}

.button-spinner {
  border: 2px solid;
  border-color: $general-text-color $general-text-color $general-text-color transparent;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
