@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables.scss';

.tag-surface-page {
  .tag-surface-page__form__item {
    display: flex;
    margin-top: 25px;
    height: 35px;
    align-items: center;
    max-width: 260px;
  }

  .tag-surface-page__form__action {
    margin-top: 25px;
    border: none;
  }
}
