@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/mixins.scss';

.reset-button {
  min-width: 40px;
  min-height: 40px;
  opacity: 0.5;
  display: flex;
  align-items: center;

  svg {
    fill: $general-text-color;
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  &:hover {
    color: $general-text-color;
    cursor: pointer;
    opacity: 1;
    .reset-button__text {
      color: $general-text-color;
    }
    svg {
      fill: $general-text-color;
    }
  }
}
.reset-button__text {
  @include generalFont(16px, 16px);
}
