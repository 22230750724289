@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables.scss';

.manual-orientation {
  .inputs-block {
    margin-top: 35px;
  }

  .manual-orientation__reset {
    margin: 18px 0 0 0;
  }

  .reset-button {
    margin-top: 20px;
  }
}
