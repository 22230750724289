@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.open-with {
  flex: 0 0 100px;

  .open-with__title {
    text-align: center;
    margin-bottom: 20px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      border-bottom: 1px solid $item-list-border-color;
    }
  }

  .open-with__title__name {
    @include generalFont(14px, 15px, $disabled-text-color);
    font-weight: 400;
    display: inline;
    background: white;
    padding: 0 20px;
    position: relative;
    z-index: 1;
  }

  .open-with__options {
    display: flex;
  }

  .open-with__options__item {
    flex: 1 1 25%;
    border-radius: $general-border-radius;
    height: 65px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include generalFont(22px, 22px, $primary-color);
    font-weight: bold;
    text-decoration: none;

    &:hover {
      background: $item-list-hover-color;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}
