@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables.scss';

$page-title-height: 55px;
.generate-support {
  @include generalFont(36px, 36px);
  height: $page-title-height;
  padding: 20px 0 0;
  font-weight: 900;
  padding-left: 70px;
  box-sizing: border-box;
  position: absolute;
}
