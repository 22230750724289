@import '../../../../assets/styles/mixins.scss';
@import '../../../../assets/styles/variables.scss';

.input-range {
  width: 100%;
  margin-top: 16px;

  .input-range__input {
    display: flex;
  }
  .input-range__input__field {
    width: 15%;
    padding: 0px 6px 0px 10px;
    margin: 0;
    border: 1px solid $main-toolbar-color;
    outline: none;
    border-radius: 0 4px 4px 0px;
    box-sizing: border-box;
  }
  .input-range__label {
    @include generalFont(14px, 14px);
    margin: 8px 0;
  }

  input[type='range'] {
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    width: 90%;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px; /* iOS */
  }

  ::-webkit-slider-runnable-track {
    background: $item-list-border-color;
  }

  /*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px; /* 1 */
    height: 40px;
    background: $main-toolbar-color;
    box-shadow: -100vw 0 0 100vw $main-toolbar-color;
  }

  ::-moz-range-track {
    height: 40px;
    background: $item-list-border-color;
  }

  ::-moz-range-thumb {
    background: $main-toolbar-color;
    height: 40px;
    width: 20px;
    // border: 3px solid #999;
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw $main-toolbar-color;
    box-sizing: border-box;
  }

  ::-ms-fill-lower {
    background: $main-toolbar-color;
  }

  ::-ms-thumb {
    background: $main-toolbar-color;
    // border: 2px solid #999;
    height: 40px;
    width: 20px;
    box-sizing: border-box;
  }

  ::-ms-ticks-after {
    display: none;
  }

  ::-ms-ticks-before {
    display: none;
  }

  ::-ms-track {
    background: $item-list-border-color;
    color: transparent;
    height: 40px;
    border: none;
  }

  ::-ms-tooltip {
    display: none;
  }
}
