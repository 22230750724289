@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/mixins.scss';

.viewer-button {
  width: 40px;
  height: 40px;
  opacity: 0.5;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: $general-text-color;
  }
}

.viewer-button.selected,
.viewer-button:hover {
  opacity: 1;

  svg {
    fill: $main-toolbar-color;
  }
}
.viewer-button:hover {
  cursor: pointer;
}
