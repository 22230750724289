@import '../../../../assets/styles/mixins.scss';
@import '../../../../assets/styles/variables.scss';

.general-number-input {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .general-number-input__label {
    @include generalFont(14px, 14px);
    margin-bottom: 10px;
  }
  .general-number-input__input {
    margin: 0;
    width: 100%;
  }
  .general-number-input__input {
    margin: 0;
    width: 100%;
  }
}
