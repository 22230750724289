@import '../../../../assets/styles/mixins.scss';
@import '../../../../assets/styles/variables.scss';

.general-select-input {
  flex: 1;
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .general-select-input__label {
    @include generalFont(14px, 14px);
    margin-bottom: 10px;
  }
  .general-select-input__input {
    margin: 0;
    width: 100%;
    padding: 6px 5px;
  }
}
