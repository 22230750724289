@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.viewer {
  position: relative;
  .viewer-content {
    position: relative;
    padding: $main-block-padding;

    .viewer-content__inset {
      width: 150px;
      height: 150px;
      position: absolute;
      bottom: 26px;
      left: 26px;
    }
    .viewer-content__icons {
      position: absolute;
      bottom: 32px;
      right: 50px;
      display: flex;
      justify-content: space-between;
    }
    canvas {
      width: 100%;
      height: 100%;
    }
  }
}
