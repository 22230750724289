@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/mixins.scss';

.next-step-button {
  background: $primary-color;
  min-height: 65px;
  position: relative;
  padding: 15px 60px 15px 20px;
  margin: 0;
  box-sizing: border-box;
  text-transform: none;
  margin-right: 70px;

  .next-step-button__direction {
    @include generalFont(12px, 12px, white);
    text-align: initial;
    font-weight: 400;
  }

  .next-step-button__step {
    @include generalFont(16px, 16px, white);
    font-weight: bold;
    text-align: initial;
  }

  &:hover {
    background: $dark-primary-color;
  }

  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    width: 35px;
    background: $dark-primary-color;
  }

  &::after {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    right: 13px;
    top: calc(50% - 7px);
    transform: rotate(225deg);
  }
}
