$general-text-color: #1e1e1e;
$warning-color: #fb4646;
$warning-color-opacity: #fb464620;

// Main toolbar colors
$main-toolbar-color: #006487;
$main-hover-toolbar-color: #004057;

$disabled-color: #dedede;
$disabled-text-color: #aaaaaa;

// Progress bar colors
$gray-background-color: #f0f0f0;
$dark-gray-background-color: #868686;
$primary-color: #39a0c0;
$dark-primary-color: #2f8fad;
$light-primary-color: #b8e3f0;
$progress-bar-color: #39a0c0;
$progress-bar-description-color: #787878;
$progress-bar-uncomplete-color: #dcdcdc;

// List colors
$item-list-border-color: #ebebeb;
$item-list-hover-color: #dce1e0;
$blue-title-color: #006487;

$mockup-color: rgba(189, 189, 255, 0.418);

// Base sizes
$general-border-radius: 12px;
$main-block-padding: 26px;
$header-height: 50px;

// Table styles
$general-table-header-background-color: #787878;
$general-table-text-color: #787878;
$general-table-active-text-color: $primary-color;
$general-table-border-color: #dce1e0;
$general-table-title-color: white;

// Preloader
$general-spinner-color: #006487;
$general-preloader-background: rgba(0, 99, 135, 0.186);
