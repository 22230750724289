@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.overall-job-progress {
  background: $gray-background-color;
  flex: 0 0 230px;
  box-sizing: border-box;
  padding: $main-block-padding;
  display: flex;
  flex-direction: column;

  .overall-job-progress__top-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .overall-job-progress__top-menu__chart {
    width: 95px;
    height: 95px;

    .CircularProgressbar-text {
      @include generalFont(20px, 20px, $progress-bar-color);
      font-weight: 900;
    }
  }

  .overall-job-progress__top-menu__title {
    @include generalFont(12px, 12px);
    margin-top: 20px;
  }

  .overall-job-progress__bottom-menu {
    margin-top: 20px;
    overflow: hidden;
    flex: 1;
  }

  .progress-list-item {
    display: flex;
  }
  .progress-list-item__data {
    padding: 0 10px 0 15px;
  }
  .progress-list-item__data__title {
    @include generalFont(12px, 16px, $progress-bar-color);
  }

  .progress-list-item:not(:last-of-type) .progress-list-item__data__title {
    margin-top: 6px;
  }

  .progress-list-item__data__description {
    margin: 5px 0 10px;
    @include generalFont(9px, 12px, $progress-bar-description-color);
  }

  .progress-list-item.disabled,
  .progress-list-item:not(.completed) {
    .progress-list-item__data__title {
      color: $disabled-text-color;
    }
  }

  .progress-list-item.next-step .progress-list-item__data__title {
    margin-top: 0;
    color: $progress-bar-color;
  }
}
