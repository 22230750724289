@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables.scss';

.orientation-type-page {
  .orientation-type-page__content__actions {
    button {
      width: 100%;
      height: 50px;
    }
  }
}
